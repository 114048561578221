<template>
  <div class="relative h-full mb-5">
    <!-- Header -->
    <div class="flex justify-between items-center bg-blue-500 px-1 py-1 text-white mb-2" >
      <h1 class="text-white flex">
        Kerkese per cmim per ditarin:
        <span @click="openDiary(useDiaryStore().discount_request?.head.diary_id)"
          class="bg-white text-blue-500 px-2 rounded-full font-semibold flex items-center ml-1 cursor-pointer">
          {{ useDiaryStore().discount_request?.head.diary_parent_id }}
          <i class="fa-solid fa-up-right-from-square text-10px mt-1 ml-1"></i>
        </span>
      </h1>
      <div class="mr-1">
        <i class="fa-regular fa-calendar-days text-14px"></i>
        {{useDiaryStore().formatDate(useDiaryStore().discount_request?.head.created_at)}}
      </div>
    </div>
    <div class="">
      <div v-if="[2, 3].includes(useDiaryStore().discount_request?.head.request_status)"
        :class="{
          'border-green-300': useDiaryStore().discount_request?.head.request_status == 2,
          'border-red-500': useDiaryStore().discount_request?.head.request_status == 3
          }"
        class="flex border rounded mb-2">
        <div class="flex items-center px-3 border-r">
          <i :class="{
          'text-green-600': useDiaryStore().discount_request?.head.request_status == 2,
          'text-red-500': useDiaryStore().discount_request?.head.request_status == 3
          }" class="fa-solid fa-info"></i>
        </div>
        <div class="pl-2 flex items-center" style="flex-grow:1;">
          <span v-if="useDiaryStore().discount_request?.head.request_status == 2"
            class="text-green-600 flex items-center"> Kerkese e konfirmuar </span>
          <span v-if="useDiaryStore().discount_request?.head.request_status == 3"> Kerkese e refuzuar </span>
        </div>
        <div class="flex flex-col border-l text-14px text-slate-400">
          <div class="border-b pl-2 pr-2">
            <i class="fa-solid fa-user text-10px"></i> {{ useDiaryStore().discount_request?.head.status_updated_by_name}}
            
          </div>
          <div class="pr-2">
            <i class="fa-regular fa-calendar-days ml-2 text-10px"></i>
            {{useDiaryStore().formatDate(useDiaryStore().discount_request?.head.status_updated_at)}}
          </div>
        </div>
      </div>
      <div v-if="useDiaryStore().discount_request?.head.note" class="mb-2">
        <div class="px-1 bg-blue-500 text-white">Shenim per kerkesen</div>
        <div class="border-r border-l border-b p-1 text-14px bg-blue-50">
          {{ useDiaryStore().discount_request?.head.note }}
        </div>
      </div>
      <div class="border mt-2 px-1 flex justify-between bg-blue-50">
        <div class="flex flex-col pb-1">
          <small class="text-slate-400 text-8px">Kerkuar nga</small>
          <span class="leading-3 text-14px">
            {{ useDiaryStore().discount_request?.head.requested_by_name }}
          </span>
        </div>
        <div class="flex flex-col pb-1 text-right">
          <small class="text-slate-400 text-8px">Caktuar per</small>
          <span class="leading-3 text-14px">
            {{ useDiaryStore().discount_request?.head.assigned_to_name }}
          </span>
        </div>
      </div>
      <div class="px-1 bg-blue-500 text-white mt-2">Info mbi klientin</div>
      <div class="border mt-2 px-1 flex justify-between bg-blue-50">
        <div class="flex flex-col pb-1">
          <small class="text-slate-400 text-8px">Klienti</small>
          <span class="leading-3 text-14px">
            {{ useDiaryStore().discount_request?.head && useDiaryStore().discount_request?.head.customer ? 
              useDiaryStore().discount_request?.head.customer[0].firstname + ' ' + 
              useDiaryStore().discount_request?.head.customer[0].lastname : '' }}
          </span>
        </div>
        <div class="flex flex-col pb-1 text-right">
          <small class="text-slate-400 text-8px">Kategoria</small>
          <span class="leading-3 text-14px">
              {{ useDiaryStore().discount_request?.head && useDiaryStore().discount_request?.head.customer ?  
              useDiaryStore().discount_request?.head.customer[0].type_id_name : ''}}
          </span>
        </div>
      </div>
      <div class="border mt-2 px-1 flex justify-between bg-blue-50">
        <div class="flex flex-col pb-1">
          <small class="text-slate-400 text-8px">Kontakt</small>
          <span class="leading-3 text-14px">
            {{ useDiaryStore().discount_request?.head && useDiaryStore().discount_request?.head.contact ? 
              useDiaryStore().discount_request?.head.contact[0].phone : '' }}
            </span>
        </div>
        <div class="flex flex-col pb-1 text-right">
          <small class="text-slate-400 text-8px">Adresa</small>
          <span class="leading-3 text-14px">
            {{ useDiaryStore().discount_request?.head && useDiaryStore().discount_request?.head.contact ? 
              useDiaryStore().discount_request?.head.contact[0].address : '' }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-1 border flex flex-col border-blue-500 bg-blue-50">
      <div class="flex justify-between items-center bg-blue-500 px-1 text-white">
        Historiku i klientit
        <span @click="useDiaryStore().discount_show_history = !useDiaryStore().discount_show_history">
          <i v-if="useDiaryStore().discount_show_history" class="fa-solid fa-chevron-down"></i>
          <i v-else class="fa-solid fa-chevron-up"></i>
        </span>
      </div>
      <div v-if="useDiaryStore().discount_show_history" class="h-48 overflow-y-auto flex flex-col w-full text-14px">
        <div class="flex px-1 text-xs text-slate-400 text-10px">
          <small class="w-1/3 flex">
            <span class="w-1/3"> # </span>
            Krijuar me
          </small>
          <small class="w-1/3 text-right"> Krijuar nga </small>
          <small class="w-1/3 text-right"> Operacioni </small>
        </div>
        <div class="overflow-y-auto border-b text-12px">
          <div
            v-for="(prev_diary, index) in useDiaryStore().previews_diaries"
            :key="index"
            class="border-b hover:bg-sky-100 cursor-pointer mb-1"
          >
            <div class="flex px-1">
              <span class="w-1/3 flex">
                <span class="w-1/3 text-slate-400">
                  {{ prev_diary.d_id }}
                </span>
                {{ new Date(prev_diary.d_created_at).toLocaleDateString() }}
              </span>
              <span class="w-1/3 text-right">{{
                prev_diary.created_by_name
              }}</span>
              <span class="w-1/3 text-right">
                {{ prev_diary.status_name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 mb-6">
      <!-- Note and client -->
      <!-- Product list -->
      <div class="">
        <div class="flex flex-col">
          <!-- Accepted / Refused text -->
          <!-- <div v-if="[2, 3].includes(useDiaryStore().discount_request?.head.request_status)"
            :class="{
              'bg-green-200' : useDiaryStore().discount_request?.head.request_status == 2,
              'bg-red-200' : useDiaryStore().discount_request?.head.request_status == 3,

              }"
            class="flex flex-col text-center border rounded mb-2 text-14px py-1">
            <span>
              Kjo kerkese per cmim eshte 
              {{useDiaryStore().discount_request?.head.request_status == 2 ? 'pranuar' : useDiaryStore().discount_request?.head.request_status == 3 ? 'refuzuar' : '' }}
            </span>
            <span class="flex items-center justify-center">
              <i class="fa-solid fa-user text-10px"></i>: {{ useDiaryStore().discount_request?.head.status_updated_by_name}}
              <i class="fa-regular fa-calendar-days ml-2 text-10px"></i>: 
              {{useDiaryStore().formatDate(useDiaryStore().discount_request?.head.status_updated_at)}}
            </span>
          </div> -->
          <div class="px-1 bg-blue-500 text-white mb-1 flex">
            <span>
              Produkte per kerkese cmimi
            </span>
            <span @click="useDiaryStore().discount_view_table = !useDiaryStore().discount_view_table" 
              class="ml-auto cursor-pointer mr-1">
              <i v-if="useDiaryStore().discount_view_table" class="fa-solid fa-table-cells"></i>
              <i v-else class="fa-regular fa-rectangle-list"></i>
            </span>
          </div>
          <!-- PRODUCT LIST - DIV view - for tablet -->
          <div v-if="!useDiaryStore().discount_view_table" class="w-full flex flex-col">
            <div v-for="detail in useDiaryStore().discount_request?.details" :key="detail.id"
              :class="[detail.discount_status == 2 ? 'bg-green-100' : detail.discount_status == 3 ? 'bg-red-100' : '']"
              class="flex w-full border mb-1 ">
              <div class="p-1 w-3/5 flex" style="align-items: stretch;">
                <div>
                  {{ detail.product_name }}
                </div>
                <!-- <div class="ml-auto flex items-center mr-3 text-green-900">
                  {{detail.product_discounted_price}}
                </div> -->
              </div>
              <div class="w-2/5 flex flex-col border-l pt-1 ml-auto">
                <!-- <pre> {{detail}} </pre> -->
                
                <div class="flex justify-between items-center border-b px-1">
                  <span class="text-slate-400 text-10px"> Cmimi i produktit: </span>
                  <span class="text-right">
                    {{useDiaryStore().price_value_filter(detail.product_price)}}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                </div>
                <div class="flex justify-between items-center border-b px-1">
                  <span class="text-slate-400 text-10px"> Sasia: </span>
                  <span class="text-right">
                    {{detail.product_amount}}
                    <span class="text-slate-400 text-10px">Cope</span>
                  </span>
                </div>
                <div v-if="detail.product_discounted_price" 
                  class="flex justify-between items-end border-b p-1">
                  <span class="text-slate-400 text-10px"> Vlera e produktit: </span>
                  <div class="flex flex-col text-right">
                    <span class="flex text-right text-lg font-semibold text-green-600 justify-end" 
                      style="line-height:14px;">
                      <span > {{ useDiaryStore().price_value_filter(detail.product_discounted_price > 0 ? parseFloat(detail.product_discounted_price) : parseFloat(detail.product_value))}} </span>
                      <span class="text-slate-400 text-10px ml-1">Leke</span>
                    </span>
                    <span v-if="detail.product_discounted_price > 0" class="text-10px text-slate-400 text-right">
                      Zbritje
                      {{parseFloat(detail.product_price) - parseFloat(detail.product_discounted_price)}} Leke 
                      ({{useDiaryStore().formatPercentage(detail.product_discounted_percent)}})
                    </span>
                  </div>
                </div>
                <div class="border-b">
                  <div class="flex p-1">
                    <span  class="text-slate-400 text-10px mr-auto">Zbritje:</span>
                    <div v-if="useDiaryStore().discount_request?.head.request_status == 1"
                      class="flex justify-end items-start">
                      <div class="flex flex-col">
                        <div v-if="am_allowed('diary_roles.can_review_discount')" class="flex">
                          <input 
                            v-model="detail.discount_value"
                            @keyup="useDiaryStore().calc_prod_discount_value_1(detail)"
                            type="number" style="height:20px;"
                            :disabled="useDiaryStore().discount_total_mode"
                            class="border rounded-l px-1 text-right border-r-0" />
                          <div @click="useDiaryStore().toggle_discount_type(detail)"
                            class="border rounded-r px-2 bg-slate-100 cursor-pointer flex items-center text-14px"
                            style="height:20px;" >
                            <span v-if="detail.discount_type == 1">%</span>
                            <span v-else>Leke</span>
                          </div>
                        </div>
                        <small class="text-10px text-slate-400">Skonto max {{useDiaryStore().discount_request?.head.max_employee_discount}}%.</small>
                      </div>
                    </div>
                    <span v-else class="text-right text-14px">
                      <span v-if="detail.discount_value">
                        {{useDiaryStore().price_value_filter(detail.discount_value)}}
                        <span v-if="detail.discount_type == 1">%</span>
                        <span v-else>Leke</span>
                      </span>
                      <span v-else class=""> Nuk ka zbritje </span>
                    </span>
                  </div>
                </div>
                <div class="flex justify-between items-center p-1">
                  <span class="text-slate-400 text-10px"> Cmimi pas zbritjes: </span>
                  <span class="text-right"
                    :class="{'font-semibold text-green-600': useDiaryStore().price_after_discount(detail) < (detail.product_discounted_price ? detail.product_discounted_price : detail.product_price)}"
                  >
                    {{ useDiaryStore().price_value_filter(useDiaryStore().price_after_discount(detail)) }}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                </div>
                <div 
                  v-if="useDiaryStore().discount_request?.head.request_status == 1 && am_allowed('diary_roles.can_review_discount') && !useDiaryStore().discount_total_mode" class="flex cursor-pointer">
                  <div @click="start_decline_discount_detail(detail)"
                    class="w-1/2 bg-red-300 text-center text-white flex items-center justify-center py-1">
                    <i class="fa-solid fa-xmark"></i>  
                  </div>
                  <div @click="start_accept_discount(detail)"
                    class="w-1/2 bg-green-300 text-center text-white flex items-center justify-center py-1 ">
                    <i class="fa-solid fa-check"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <small class="text-10px text-slate-400 text-right">
            Skonto max {{useDiaryStore().price_value_filter(useDiaryStore().discount_request?.head.max_employee_discount)}}.
          </small>
          <!-- PRODUCT LIST - TABLE view - for tablet -->
          <table v-if="useDiaryStore().discount_view_table"
            class="table-auto text-14px">
            <thead>
              <tr>
                <th class="py-1 pl-1">Product</th>
                <th class="text-right py-1">Cmimi aktual</th>
                <th class="text-right py-1">Cmimi i produktit</th>
                <th class="text-right py-1">Zbritje</th>
                <th class="text-right py-1">Vlera e zbritjes</th>
                <th class="text-right py-1">Cmimi pas zbritjes</th>
                <th class="text-right w-14">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="detail in useDiaryStore().discount_request?.details" :key="detail.id" 
                class="border-b"
                :class="[detail.discount_status == 2 ? 'bg-green-100' : detail.discount_status == 3 ? 'bg-red-100' : '']">
                <td class="pl-2 py-1">{{detail.product_name}}</td>
                <td class="text-right align-middle">
                  <span > {{ useDiaryStore().price_value_filter(detail.product_discounted_price > 0 ? parseFloat(detail.product_discounted_price) : parseFloat(detail.product_price))}} </span>
                  <span class="text-slate-400 text-10px ml-1 pr-1">Leke</span>
                </td>
                <td class="text-right align-middle">
                  {{useDiaryStore().price_value_filter(detail.product_price)}}
                  <span class="text-slate-400 text-10px pr-1">Leke</span>
                </td>
                <td class="text-right align-middle" style="vertical-align: middle;">
                  <div v-if="useDiaryStore().discount_request?.head.request_status == 1"
                    class="flex justify-end items-start">
                    <div class="flex flex-col">
                      <div v-if="am_allowed('diary_roles.can_review_discount')" class="flex">
                        <input v-if="am_allowed('diary_roles.can_review_discount')"
                          v-model="detail.discount_value"
                          @keyup="useDiaryStore().calc_prod_discount_value_1(detail)"
                          type="number" style="height:20px;"
                          class="border rounded-l px-1 text-right border-r-0 w-16" />
                        <div @click="useDiaryStore().toggle_discount_type(detail)"
                          class="border rounded-r px-2 bg-slate-100 cursor-pointer flex items-center text-14px"
                          style="height:20px;" >
                          <span v-if="detail.discount_type == 1">%</span>
                          <span v-else>Leke</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span v-else class="text-right text-14px">
                    <span v-if="detail.discount_value">
                      {{useDiaryStore().price_value_filter(detail.discount_value)}}
                      <span v-if="detail.discount_type == 1">%</span>
                      <span v-else>Leke</span>
                    </span>
                    <span v-else class=""> Nuk ka zbritje </span>
                  </span>
                </td>
                <td class="text-right align-middle">
                  <span v-if="detail.product_discounted_price > 0" class="text-10px text-slate-400 text-right">
                    Zbritje
                    {{parseFloat(detail.product_price) - parseFloat(detail.product_discounted_price)}} Leke 
                    ({{useDiaryStore().formatPercentage(detail.product_discounted_percent)}})
                  </span>
                </td>
                <td class="text-right align-middle">
                  <span class="text-right pr-1"
                    :class="{'font-semibold text-green-600': useDiaryStore().price_after_discount(detail) < (detail.product_discounted_price ? detail.product_discounted_price : detail.product_price)}"
                  >
                    {{ useDiaryStore().price_value_filter(useDiaryStore().price_after_discount(detail)) }}
                    <span class="text-slate-400 text-10px">Leke</span>
                  </span>
                </td>
                <td class="align-middle">
                  <div v-if="useDiaryStore().discount_request?.head.request_status == 1 && am_allowed('diary_roles.can_review_discount')" class="flex cursor-pointer w-14 ">
                    <div @click="start_decline_discount_detail(detail)"
                      class="w-1/2 bg-red-300 text-center text-white flex items-center justify-center py-1">
                      <i class="fa-solid fa-xmark"></i>  
                    </div>
                    <div @click="start_accept_discount(detail)"
                      class="w-1/2 bg-green-300 text-center text-white flex items-center justify-center py-1 ">
                      <i class="fa-solid fa-check"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <!-- <tr>
                <td>Footer</td>
              </tr> -->
            </tfoot>
          </table>

          <div v-if="useDiaryStore().discount_request?.head.request_status == 1" class="flex flex-col items-end mt-3 w-2/5 border-l ml-auto">
            <div class="justify-end flex">
              <div v-if="!useDiaryStore().discount_total_mode"
                class="bg-green-400 text-white px-1">
                <a @click.prevent="useDiaryStore().toggle_total_discount_mode()" 
                href="" 
                class=" px-1">Jep skonto ne total</a>
              </div>
              <div v-else class=" ">
                <span class="flex items-center">
                  <span class="mr-2"> Vlera zbritjes ndaj totalit </span>
                  <a @click.prevent="useDiaryStore().toggle_total_discount_mode" href="" class="delete is-small ml-auto "></a>
                </span>
                <span class="flex w-64">
                  <input v-model="useDiaryStore().total_discount_value"
                    class="border text-right px-2"
                    type="numer" >
                    <span @click="useDiaryStore().calculate_discount_total()" class="bg-green-400 px-2 cursor-pointer text-white">
                      <i class="fa-solid fa-calculator"></i>
                    </span>
                </span>
              </div>
            </div>
            <div class="my-2 flex flex-col text-right">
              <span>
                Total: 
                {{ !useDiaryStore().discount_request_diary_totals.total_after_discount ? useDiaryStore().price_filter(useDiaryStore().discount_request_diary_totals.total_product) : useDiaryStore().price_filter(useDiaryStore().discount_request_diary_totals.total_after_discount)}}
              </span>
              <span v-if="useDiaryStore().discount_request_diary_totals.total_discount_value">
                Total pas zbritjes: {{ useDiaryStore().price_filter(useDiaryStore().discount_request_diary_totals.total_product - useDiaryStore().total_discount_value)}}
              </span>
            </div>
            <div v-if="useDiaryStore().save_total_discount_mode" class="w-64 flex">
              <a @click.prevent="useDiaryStore().save_total_discount()" class="bg-green-400 text-white px-1 text-center w-full" href="">Ruaj skonton totale</a>
            </div>
            <!-- <pre> {{useDiaryStore().discount_request_diary_totals}} </pre> -->
          </div>

        </div>
      </div>
    </div>

    <!-- Discount actions -->
    <div v-if="useDiaryStore().discount_request?.head.request_status == 1 && 
      am_allowed('diary_roles.can_review_discount')"
      class="buttons-row flex items-center  px-2 mt-3">
      <span @click="useDiaryStore().reject_discount_request()"
        class="btn btn-sm btn-primary text-white 
          hover:border-black border p-2 bg-red-800 shadow-md mr-auto 
           cursor-pointer">
        <i class="fa-solid fa-xmark"></i> Refuzo kerkesen
      </span>

      <span v-if="useDiaryStore().loading_close_discount" 
        class="btn btn-sm btn-primary text-white 
          hover:border-black border p-2 bg-green-800 shadow-md  
          hover:bg-green-900 cursor-pointer">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
      <span v-else
        @click="useDiaryStore().close_discount_request()"
        class="btn btn-sm btn-primary text-white 
          hover:border-black border p-2 bg-green-800 shadow-md  
          hover:bg-green-900 cursor-pointer">
        <i class="fa-solid fa-check mr-1"></i> Perfundo kerkesen
      </span>
    </div>
  </div>
</template>

<script>
  import permissionMixin from '@/mixins/permissions-mixin.js'
  export default {
      mixins: [permissionMixin],
      created(){
        if(!this.am_allowed("diary_roles.can_see_request_discount")) {
          window.location.href = "/home";
        }

      }
  }
</script>

<script setup>
import { ref, onMounted, inject } from "vue";
import { useDiaryStore } from "@/stores/diary";
import { useRouter } from "vue-router";

const router = useRouter();
const loadingPage = ref(true);
const routeParams = ref(router.currentRoute.value.params);
const toast = inject("toast");

const start_accept_discount = async (detail) => {
  if(useDiaryStore().price_after_discount(detail) > (detail.product_discounted_price > 0 ? parseFloat(detail.product_discounted_price) : parseFloat(detail.product_value))){
    toast.error("'Cmimi pas zbritjes' duhet te jete me i vogel se 'Cmimi aktual'.");
    return 
  }
  if(detail.discount_type == 1){ // If discount_type is %
    if(useDiaryStore().discount_request?.head.max_employee_discount < detail.discount_value) {
      toast.error("Maksimumi i skontos qe mund te jepni ju eshte " + useDiaryStore().format_percentage(useDiaryStore().price_value_filter(useDiaryStore().discount_request?.head.max_employee_discount)));
      return
    }
  } else if(detail.discount_type == 1) { //If discount_type is Leke
    // Calculate the max discount in value
    let max_discount_value = (parseFloat(detail.product_value) * useDiaryStore().discount_request?.head.max_employee_discount) / 100
    if(max_discount_value < detail.discount_value) {
      toast.error("Maksimumi i skontos qe mund te jepni ju eshte " + max_discount_value + "Leke");
      return
    }

  }
  // TODO: check if the discount givven exeeds the "useDiaryStore().discount_request?.head.max_employee_discount"

  if(detail.discount_value){
    var res = await useDiaryStore().accept_discount(detail);
    if (res.toaster == "success") {
      toast.success("Ndryshimi u ruajt me suksess.");
    }
  }
  else {
    toast.error("Ju lutem vendosni shumen e zbritjes.");
  }
};


const start_decline_discount_detail = async (detail) => {
  var res = await useDiaryStore().decline_discount_detail(detail)
  if (res.toaster == "success") {
    toast.success("Ndryshimi u ruajt me suksess.");
  }
}

const openDiary = (id) => {
  router.push({ name: 'Ditar Shiko', params: { id } })
}

onMounted(async () => {
  // Accessing route parameters

  // Get diary discount request
  await useDiaryStore().get_diary_discount_request(routeParams.value.id);

  // Get client previews diaries
  await useDiaryStore().get_customer_previews_diaries(
    useDiaryStore().discount_request?.head.customer_id,
    routeParams.value.id
  );

  await useDiaryStore().getDiary(useDiaryStore().discount_request?.head.diary_id)
  

  // Get Client info and contact
  // await useDiaryStore().diary_client_info(
  //   useDiaryStore().discount_request?.head,
  // )

  // Set loading_page to false once the component is mounted
  loadingPage.value = false;
});
</script>

<style>
  /* Add your styles here if needed */
  @import './style.css'
</style>